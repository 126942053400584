
    document.addEventListener('DOMContentLoaded', () => {
    const tabMenus = document.querySelectorAll('.tab-menu');
    const tabPanels = document.querySelectorAll('.tab-content-panel');
    const serviceImages = document.querySelectorAll('.services-wrapper-images img');

    function switchTab(tabIndex) {
        // Remove active classes
        tabMenus.forEach(menu => menu.classList.remove('active'));
        tabPanels.forEach(panel => panel.classList.remove('active'));
        serviceImages.forEach(image => image.classList.remove('active'));

        // Add active classes to selected elements
        tabMenus[tabIndex].classList.add('active');
        tabPanels[tabIndex].classList.add('active');
        serviceImages[tabIndex].classList.add('active');
    }

    // Add click event listeners to tabs
    tabMenus.forEach((tab, index) => {
        tab.addEventListener('click', (e) => {
        e.preventDefault();
        switchTab(index);
        });
    });

    // Initialize first tab
    switchTab(0);
    });
